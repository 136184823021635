import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Chat, ChatMessage, Task} from '@interfaces/task';
import isAdminState from "@state/globalState/isAdminState";
import {FaCircleNotch} from 'react-icons/fa';
import style from './style.module.scss';

import sendMessage, {SendMessageOptions} from '@hornet-api/task/sendMessage';
import UsersTypingList from "@components/NotificationSidebar/UsersTypingList";
import notifyUserTyping from "@hornet-api/chat/notifyUserTyping";
import {typingDurationTime} from "@components/NotificationSidebar/utils";

type Props = {
  task?: Task,
  chat?: Chat,
  messageToEdit?: ChatMessage | null,
  onSubmitted?: () => void | Promise<void>,
  isAnnouncement?: null | string
}
const MessageInput = React.forwardRef(
  (
    {
      task,
      chat,
      onSubmitted,
      isAnnouncement = null,
      messageToEdit
    }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    const [isLoading, setIsLoading] = useState(false)
    const [text, setText] = useState('');
    const isAdmin = isAdminState.useValue();
    const bottomRef = useRef<HTMLDivElement>(null);
    const lastSentRef = useRef(0);
    const typingTimeoutRef = useRef<number | null>(null); // Timer reference

    useEffect(() => {
      bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [task]);

    const chatId = useMemo(() => {
      return chat?.id || task?.chat?.id
    }, [chat, task]);

    useEffect(() => {
      setText(messageToEdit?.content || '')
    }, [messageToEdit])

    const onSubmit = async (e?: React.FormEvent) => {
      if (e) e.preventDefault();
      if (isLoading) return;

      let opts: SendMessageOptions = {
        content: text
      };
      if (task) {
        opts.taskId = task.id;
        if (isAdmin) {
          if ('contact' in task) opts.contactId = task.contact;
          if ('entity' in task) opts.companyId = task.entity;
        } else if ('entity' in task) {
          opts.contactCompanyId = task.entity;
        }
      } else if (chat) {
        if (isAdmin) {
          if ('contact' in chat) opts.contactId = chat.contact;
          if ('entity' in chat) opts.companyId = chat.entity;
          if ('loan' in chat) opts.loanId = chat.loan;
        } else if ('entity' in chat) {
          opts.contactCompanyId = chat.entity;
        } else if ('loan' in chat) {
          opts.loanId = chat.loan;
        }
      } else {
        alert('Not supported');
        return;
      }
      if (messageToEdit && isAdmin) {
        opts.id = messageToEdit.id
      }
      opts.isAnnouncement = isAnnouncement;
      setIsLoading(true);
      await sendMessage(opts);
      // callback when done
      setText('');
      if (onSubmitted) {
        await onSubmitted();
      }
      setIsLoading(false);
    };

    const sendTypingNotification = () => {
      const currentTime = Date.now();
      const timeSinceLastSend = currentTime - lastSentRef.current;

      // Only send the notification if 10 seconds have passed since the last one or if it's the first time typing
      if (timeSinceLastSend > typingDurationTime && text && chatId) {
        lastSentRef.current = currentTime; // Update the last sent time
        notifyUserTyping(chatId).catch(() => {
          lastSentRef.current = 0;
        });
      }
    };

    useEffect(() => {
      if (text && chatId && !isAnnouncement) {
        // Clear any existing timeout before setting a new one
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }

        // Send an immediate notification when typing starts
        let timeOutIn = typingDurationTime;
        if (lastSentRef.current === 0) {
          sendTypingNotification();
        } else {
          const currentTime = Date.now();
          const timeSinceLastSend = currentTime - lastSentRef.current
          timeOutIn = typingDurationTime - timeSinceLastSend
        }

        // Set a timeout for 10 seconds to send the next notification if they keep typing
        typingTimeoutRef.current = window.setTimeout(() => {
          sendTypingNotification();
        }, timeOutIn) as unknown as number; // Explicit cast to number
      } else if (typingTimeoutRef.current) {
        // If typing stops, clear the timeout
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = null; // Reset timeout reference
        lastSentRef.current = 0; // Reset the last sent time
      }

      return () => {
        // Cleanup on unmount or before re-running useEffect
        if (typingTimeoutRef.current) {
          clearTimeout(typingTimeoutRef.current);
        }
      };
    }, [text, chatId]);

    if (!chatId) {
      return null;
    }

    return (<>
      <div className={`border-top ${style.messageInput}`}>
        <UsersTypingList chatId={chatId}/>
        <form onSubmit={onSubmit}>
        <textarea
          rows={3}
          value={text}
          className="form-control"
          placeholder='Write message here..'
          onChange={(e) => {
            setText(e.target.value);
          }}
          ref={ref}
          onKeyDown={(e) => {
            //handle enter to submit and shift enter to do a new line
            // 13 represents the Enter key
            if (e.code === "Enter" && !e.shiftKey) {
              e.preventDefault();
              onSubmit().then()
            }
          }}
        />
          <div className={style.btnBox} ref={bottomRef}>
            <button
              type="submit"
              className='btn btn-primary btn-sm'
              disabled={isLoading}
            >
              {isLoading ? <FaCircleNotch className='spin'/> : 'Send Message'}
            </button>
          </div>
        </form>
      </div>
    </>);
  })

export default MessageInput;