import {createGlobalState} from "react-global-hooks";
import {ContactListItem} from "@hornet-api/task/getContactList";
import {CompanyListItem} from "@hornet-api/task/getCompanyList";
import {PropertyOptions} from "@hornet-api/properties/getPropertyListOptions";
import {LpaData} from "@hornet-api/loanParticipationAgreement/getLpaList";
import {InvestorItem} from "@hornet-api/investor/getInvestorData";
import {LoanItems} from "@hornet-api/task/getGlobalLoanList";
import {IAchItem} from "@hornet-api/transactions/getAchListData";
import {IBorrowerApplicationItem} from "@hornet-api/borrower/getBorrowerApplicationListData";
import {IAmbReferralItem} from "@hornet-api/ambassador/getAmbassadorListData";
import {ITaskItem} from "@hornet-api/task/getTaskListData";
import {currency, formatDate, getComparatorByKey} from "@common/basic";
import {
  ACHCategoryEnum,
  EmailLogEnum,
  GlobalSearchEntityTypeEnum,
  InvestmentClassEnum,
  LoanStageEnum,
  ReferralStatusEnum,
  SubscriptionAgreementStatusEnum,
  TaskStatusEnum
} from "@interfaces/GeneratedEnums";
import {IEmailLog} from "@hornet-api/emailLog/getEmailLogData";
import {IRecentSearch} from "@hornet-api/recentGlobalSearch/getRecentGlobalSearchList";
import {fundListState, loanListState} from "@components/NotificationSidebar/globalState";


export enum ITabStatus {
  INACTIVE,
  ACTIVE

}

export enum SearchTabEnum {
  ALL = 'All / None',
  CONTACT = 'Contacts ',
  ENTITY = 'Entities',
  LOAN = 'Loans',
  FUND = 'Fund Accounts',
  PROPERTY = 'Properties',
  LPA = 'Loan Participations',
  ACH = 'ACH Transfers',
  BPA = 'Borrower Applications',
  AR = 'Ambassador Program',
  TASK = 'Tasks',
  EMAIL = 'Email Log',
  RS = 'Recent Search'
}

export interface ITab {
  tab: keyof typeof SearchTabEnum,
  status: ITabStatus
}

export const SearchTabKeys = Object.keys(SearchTabEnum) as (keyof typeof SearchTabEnum)[];

enum SEPARATOR {
  AT = '@@',
  COMMA = ', '
}

export interface ISearchableObjectData {
  text: string,
  altText: (string | number)[];
  relationships: {
    [K in keyof typeof GlobalSearchEntityTypeEnum]?: (number | string)[]
  },
  entityType: keyof typeof GlobalSearchEntityTypeEnum;
  others?: {
    phone?: string | null;
    userId?: string |number| null;
    loanStage?: keyof typeof LoanStageEnum;
    contactId?: number | null;
  };
  nestedEntityType?: keyof typeof GlobalSearchEntityTypeEnum;
}

interface ISearchableObject {
  [id: number | string]: ISearchableObjectData
}

export type IGlobalSearchState = {
  [K in keyof typeof GlobalSearchEntityTypeEnum]: ISearchableObject;
}

type IGlobalSearchLoadingState = {
  [K in keyof typeof GlobalSearchEntityTypeEnum]: boolean;
}

export const IconMap: { [key in keyof typeof GlobalSearchEntityTypeEnum]: string } = {
  CONTACT: 'users',
  ENTITY: 'building',
  PROPERTY: 'home',
  LOAN: 'usd',
  LPA: 'file-text',
  FUND: 'money',
  ACH: 'exchange',
  BPA: 'file-text-o',
  AR: 'handshake-o',
  TASK: 'tasks',
  EMAIL: 'at',
  RS: 'history'
}

export const generateLink = (primaryId: string | number, entityType: keyof typeof GlobalSearchEntityTypeEnum) => {
  switch (entityType) {
    case 'CONTACT':
      return `/contact/show/${primaryId}`;
    case 'ENTITY':
      return `/entity/show/${primaryId}`;
    case 'LOAN':
      return `/loan/show/${primaryId}`;
    case 'FUND':
      return `/investor/show/${primaryId}`;
    case 'PROPERTY':
      return `/property/show/${primaryId}`;
    case 'LPA':
      return `/loanParticipationAgreement/edit/${primaryId}`;
    case 'ACH':
      return `/admin/transactions`; // loan payment, investment, lpa
    case 'BPA':
      return `/borrower/application/${primaryId}`;
    case 'AR':
      return `/contact/show/${primaryId}`;
    case 'TASK':
      return `/tasks/edit/${primaryId}`;
    case 'EMAIL':
      return `/emailLog/show/${primaryId}`;
    case 'RS':
      return ``;
    default:
      return ``;
  }
}

export interface ICleanSearchableObject extends ISearchableObjectData {
  id: string | number;
}

export type ICleanSearchableObjectMap = {
  FUND?: ICleanSearchableObject[];
  LPA?: ICleanSearchableObject[];
  LOAN?: ICleanSearchableObject[];
  PROPERTY?: ICleanSearchableObject[];
  CONTACT?: ICleanSearchableObject[];
  ENTITY?: ICleanSearchableObject[];
  ACH?: ICleanSearchableObject[];
  BPA?: ICleanSearchableObject[];
  AR?: ICleanSearchableObject[];
  TASK?: ICleanSearchableObject[];
  EMAIL?: ICleanSearchableObject[];
  RS?: ICleanSearchableObject[];
}

export const defaultGlobalSearchData: IGlobalSearchState = {
  FUND: {},
  LPA: {},
  LOAN: {},
  PROPERTY: {},
  CONTACT: {},
  ENTITY: {},
  ACH: {},
  BPA: {},
  AR: {},
  TASK: {},
  EMAIL: {},
  RS: {},
}

const defaultGlobalSearchLoadingData: IGlobalSearchLoadingState = {
  FUND: false,
  LPA: false,
  LOAN: false,
  PROPERTY: false,
  CONTACT: false,
  ENTITY: false,
  ACH: false,
  BPA: false,
  AR: false,
  TASK: false,
  EMAIL: false,
  RS: true,
}


export const globalSearchDataState = createGlobalState<IGlobalSearchState>(defaultGlobalSearchData);

export const globalSearchDataLoadingState = createGlobalState<IGlobalSearchLoadingState>(defaultGlobalSearchLoadingData);

const splitContent = (data: string | null, separator: SEPARATOR): string[] => {
  return data?.split(separator) || []
}

const removeNullAndBlank = (list: (string | number | null)[]): string[] => {
  const filteredList = list.filter(item => item !== null && String(item).trim() !== "") as string[];
  return [...new Set(filteredList)];
}

export const sortSearchableObjectData = (filteredData: ICleanSearchableObject[], sortOrder: string): ICleanSearchableObject[] => {
  return filteredData.sort(sortOrder === 'DESC' ? (a, b) => b.text.localeCompare(a.text) : (a, b) => a.text.localeCompare(b.text));
}

const formatContacts = (contactList: ContactListItem[]): ISearchableObject => {
  return contactList.reduce((acc: ISearchableObject, contact: ContactListItem) => {
    acc[contact.id] = {
      text: contact.name,
      altText: removeNullAndBlank([contact.username, contact.email, ...splitContent(contact.fullAddresses, SEPARATOR.AT), ...splitContent(contact.phoneNumbers, SEPARATOR.AT)]),
      relationships: {'PROPERTY': removeNullAndBlank(splitContent(contact.property, SEPARATOR.AT))},
      entityType: 'CONTACT',
      others: {phone: contact.primaryPhoneNumber,userId:contact.userId},
    };
    return acc;
  }, {});
}

const formatCompany = (companyList: CompanyListItem[]): ISearchableObject => {
  return companyList.reduce((acc: ISearchableObject, company: CompanyListItem) => {
    acc[company.id] = {
      text: company.name,
      altText: removeNullAndBlank([...splitContent(company.fullAddresses, SEPARATOR.AT), ...splitContent(company.phoneNumbers, SEPARATOR.COMMA)]),
      relationships: {
        CONTACT: removeNullAndBlank([...splitContent(company.contacts, SEPARATOR.COMMA)]),
        PROPERTY: removeNullAndBlank(splitContent(company.properties, SEPARATOR.AT))
      },
      entityType: 'ENTITY',
    };
    return acc;
  }, {});
}

const formatProperty = (propertyList: PropertyOptions[]): ISearchableObject => {
  return propertyList.reduce((acc: ISearchableObject, property: PropertyOptions) => {
    acc[property.id] = {
      text: property.address,
      altText: removeNullAndBlank([property.type, property.category]),
      relationships: {
        CONTACT: removeNullAndBlank([property.contact]),
        ENTITY: removeNullAndBlank([property.company]),
        LOAN: removeNullAndBlank([...splitContent(property.loans, SEPARATOR.AT)]),
      },
      entityType: 'PROPERTY',
    };
    return acc;
  }, {});
}

const formatLpa = (lpaList: LpaData[]): ISearchableObject => {
  return lpaList.reduce((acc: ISearchableObject, lpa: LpaData) => {
    acc[lpa.id] = {
      text: `${lpa.aliasId} - ${lpa.investor}`,
      altText: removeNullAndBlank([currency(lpa.purchasePrice), formatDate(lpa.effectiveDate)]),
      relationships: {
        CONTACT: removeNullAndBlank([lpa.contact]),
        ENTITY: removeNullAndBlank([lpa.company]),
        PROPERTY: removeNullAndBlank([lpa.primaryProperty])
      },
      entityType: 'LPA',
    };
    return acc;
  }, {});
}

const formatFund = (fundList: InvestorItem[]) => {
  return fundList.reduce((acc: ISearchableObject, fund: InvestorItem) => {
    acc[fund.id] = {
      text: fund.investor,
      altText: [InvestmentClassEnum[fund.class],
        SubscriptionAgreementStatusEnum[fund.status],
        currency(fund.totalInvestment)],
      relationships: {
        CONTACT: removeNullAndBlank([...splitContent(fund.contacts, SEPARATOR.COMMA)]),
        ENTITY: removeNullAndBlank([fund.company])
      },
      entityType: 'FUND',
    }
    return acc;
  }, {});
}

const formatLoan = (loanList: LoanItems[]) => {
  return loanList.reduce((acc: ISearchableObject, loan: LoanItems) => {
    acc[loan.id] = {
      text: `${loan.id} - ${loan.fullAddress}`,
      altText: removeNullAndBlank([loan.primaryBorrowerContactName, loan.primaryBorrowerEntityName]),
      relationships: {
        CONTACT: removeNullAndBlank([
          ...splitContent(loan.brokerContact, SEPARATOR.COMMA),
          ...splitContent(loan.lenderContact, SEPARATOR.COMMA),
          ...splitContent(loan.originatorContact, SEPARATOR.COMMA),
          ...splitContent(loan.guarantor, SEPARATOR.COMMA),
          ...splitContent(loan.escrowOfficers, SEPARATOR.COMMA),
          ...splitContent(loan.loanBorrowerContactIds, SEPARATOR.COMMA)
        ]),
        ENTITY: removeNullAndBlank([
          ...splitContent(loan.brokerEntity, SEPARATOR.COMMA),
          ...splitContent(loan.originatorEntity, SEPARATOR.COMMA),
          ...splitContent(loan.lenderEntity, SEPARATOR.COMMA),
          ...splitContent(loan.loanBorrowerEntityIds, SEPARATOR.COMMA)
        ])
      },
      others: {loanStage: loan.loanStage},
      entityType: 'LOAN',
    };
    return acc;
  }, {});
}
const formatAch = (achList: IAchItem[]) => {
  return achList.reduce((acc: ISearchableObject, ach: IAchItem) => {
    acc[ach.id] = {
      text: `${ACHCategoryEnum[ach.category]} - ${ach.investor}`,
      altText: [formatDate(ach.dateCreated), currency(ach.amount)],
      relationships: {
        CONTACT: removeNullAndBlank([ach.contact]),
        ENTITY: removeNullAndBlank([ach.company])
      },
      entityType: 'ACH',
    };
    return acc;
  }, {});
}
const formatRecentSearch = (recentSearches: IRecentSearch[]) => {
  const globalSearchData = globalSearchDataState.get()
  return recentSearches.reduce((acc: ISearchableObject, item: IRecentSearch) => {
    const matchedItem = globalSearchData[item.entityType][item.primaryId]
    if (matchedItem) {
      acc[item.primaryId] = {
        ...matchedItem,
        nestedEntityType: item.entityType,
        entityType: 'RS',
      };
    }
    return acc;
  }, {});
}
const formatBpa = (bpaList: IBorrowerApplicationItem[]) => {
  return bpaList.reduce((acc: ISearchableObject, bpa: IBorrowerApplicationItem) => {
    acc[bpa.id] = {
      text: bpa.contactName,
      altText: removeNullAndBlank([bpa.address, bpa.companyName]),
      relationships: {
        CONTACT: removeNullAndBlank([bpa.contact]),
        ENTITY: removeNullAndBlank([bpa.company]),
        LOAN: removeNullAndBlank([bpa.loan])
      },
      entityType: 'BPA',
    };
    return acc;
  }, {});
}
const formatAmbReferral = (ambReferrals: IAmbReferralItem[]) => {
  return ambReferrals.reduce((acc: ISearchableObject, {
    id,
    contactId,
    referralName,
    referralEmail,
    status,
    ambassadorId
  }: IAmbReferralItem) => {
    const contactMap = globalSearchDataState.get().CONTACT;
    acc[contactId || -id] = {
      text: (contactId && contactMap[contactId]?.text) || referralName || '',
      altText: removeNullAndBlank([referralEmail, ReferralStatusEnum[status], contactMap[ambassadorId]?.text]),
      // ambassadorId is supposed to be not null
      relationships: {CONTACT: [ambassadorId]},
      entityType: 'AR',
      others: {
        contactId: contactId
      }
    };
    return acc;
  }, {});
}
const formatTask = (taskList: ITaskItem[]) => {
  return taskList.reduce((acc: ISearchableObject, task: ITaskItem) => {
    acc[task.id] = {
      text: task.title,
      altText: [task.ownerName, TaskStatusEnum[task.status]],
      relationships: {
        CONTACT: removeNullAndBlank([task.contact]),
        ENTITY: removeNullAndBlank([task.company]),
        LOAN: removeNullAndBlank([task.loan]),
        PROPERTY: removeNullAndBlank([task.property])
      },
      entityType: 'TASK',
    };
    return acc;
  }, {});
}
const formatEmailLogs = (emailLogs: IEmailLog[]) => {
  return emailLogs.reduce((acc: ISearchableObject, emailLog: IEmailLog) => {
    acc[emailLog.id] = {
      text: emailLog.subject,
      altText: [EmailLogEnum[emailLog.status], formatDate(emailLog.lastUpdated), emailLog.recipients],
      relationships: {
        CONTACT: removeNullAndBlank([emailLog.contact])
      },
      entityType: 'EMAIL',
    };
    return acc;
  }, {});
}

export const formatAndSetContact = (contactList: ContactListItem[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'CONTACT': formatContacts(contactList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'CONTACT': true});
}

export const formatAndSetCompany = (companyList: CompanyListItem[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'ENTITY': formatCompany(companyList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'ENTITY': true});
}

export const formatAndSetProperty = (propertyList: PropertyOptions[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'PROPERTY': formatProperty(propertyList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'PROPERTY': true});
}

export const formatAndSetLpa = (lpaList: LpaData[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'LPA': formatLpa(lpaList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'LPA': true});
}

export const formatAndSetFund = (fundList: InvestorItem[]) => {
  fundListState.set(fundList.map((fund) => ({
    value: fund.id,
    label: `${fund.investor} -> ${fund.class} (${fund.status}) (${fund.distributionElection})`
  })));
  globalSearchDataState.set({...globalSearchDataState.get(), 'FUND': formatFund(fundList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'FUND': true});
}

export const formatAndSetLoan = (loanList: LoanItems[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'LOAN': formatLoan(loanList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'LOAN': true});

  loanList = loanList.filter(x => x).sort(getComparatorByKey('id'));
  const loanListItems = loanList.map(item => ({
    id: item.id,
    primaryProperty: item.fullAddress || null, // Assuming fullAddress maps to primaryProperty
    loanStage: item.loanStage
  }));
  loanListState.set(loanListItems);
}
export const formatAndSetAch = (achList: IAchItem[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'ACH': formatAch(achList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'ACH': true});
}
export const formatAndSetBpa = (bpaList: IBorrowerApplicationItem[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'BPA': formatBpa(bpaList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'BPA': true});
}
export const formatAndSetRecentSearch = (recentSearches: IRecentSearch[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'RS': formatRecentSearch(recentSearches)});
}
export const formatAndSetAmbReferral = (ambReferrals: IAmbReferralItem[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'AR': formatAmbReferral(ambReferrals)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'AR': true});
}
export const formatAndSetTask = (taskList: ITaskItem[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'TASK': formatTask(taskList)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'TASK': true});
}
export const formatAndSetEmailLogs = (emails: IEmailLog[]) => {
  globalSearchDataState.set({...globalSearchDataState.get(), 'EMAIL': formatEmailLogs(emails)});
  globalSearchDataLoadingState.set({...globalSearchDataLoadingState.get(), 'EMAIL': true});
}
